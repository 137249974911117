<template>
  <div class="courseDetail">
    <div class="title">{{ courseName }}</div>
    <div class="contanier">
      <div
        class="zhang"
        v-for="(zhangItem, zhangIndex) in courserList"
        :key="zhangItem.id"
      >
        <div v-if="type === 'download'" class="zhang-name download">
          <span>{{ zhangItem.name }}</span>
          <a :href="zhangItem.attachments[0].url"
            ><span class="download-btn">点击下载</span></a
          >
        </div>
        <div v-if="type === 'course'" class="zhang-name">
          {{ zhangItem.name }}
        </div>
        <div
          class="jie-name"
          v-for="(jieItem, jieIndex) in zhangItem.children"
          :key="jieItem.id"
          @click="toVideoPlay(jieItem)"
        >
          {{ zhangIndex + 1 }}-{{ jieIndex + 1 }} {{ jieItem.name }}
          <span v-if="!isMember && !jieItem.onlyForMember" class="free-tip"
            >免费</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "courseDetail",
  data() {
    return {
      courseId: null,
      courseName: "",
      courserList: [],
      isMember: false,
      type: "",
    };
  },
  created() {
    this.courseId = this.$router.history.current.query.id;
    this.courseName = this.$router.history.current.query.name;
    this.type = this.$router.history.current.query.type;
    if (this.type === "course") {
      this.getCourserList();
    } else if (this.type === "download") {
      this.getDownloadList();
    }
    this.getUserInfo();
  },
  methods: {
    //获取用户信息
    getUserInfo() {
      this.axios
        .get("/loginInfo")
        .then((res) => {
          this.isMember = res.data.isMember;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取课程列表
    getCourserList() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.axios
        .get("/api/common/imageText/anon/courseList", {
          params: {
            courseId: this.courseId,
          },
        })
        .then((res) => {
          loading.close();
          this.courserList = res.data;
        });
    },
    // 去播放课程
    toVideoPlay(jieItem) {
      if (!this.isMember && jieItem.onlyForMember) {
        this.$message({
          message: "仅会员可观看",
          type: "warning",
        });
        return;
      }
      this.$router.push(
        "/videoPlay?id=" +
          jieItem.id +
          "&courseId=" +
          this.courseId +
          "&drawerTitle=" +
          this.courseName
      );
    },
    // 获取资料下载列表
    getDownloadList() {
      this.axios
        .get("/api/common/imageText/anon/normalList", {
          params: {
            classificationIds: this.courseId,
            mainType: "WRITTEN",
            limit: 1000,
            offset: 0,
          },
        })
        .then((res) => {
          console.log(res);
          for (let i = 0; i < res.data.list.length; i++) {
            res.data.list[i].attachments = JSON.parse(
              res.data.list[i].attachments
            );
          }
          this.courserList = res.data.list;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.courseDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    width: 800px;
    font-size: 24px;
    color: #1c1f21;
    letter-spacing: 0px;
    line-height: 60px;
    font-weight: bold;
  }
  .contanier {
    width: 800px;
    .zhang {
      margin-bottom: 8px;
      padding: 24px 32px 15px;
      background: #fff;
      box-shadow: 0 8px 16px 0 rgba(7, 17, 27, 0.1);
      border-radius: 12px;
      .zhang-name {
        color: #1c1f21;
        font-size: 16px;
        font-weight: 700;
        line-height: 30px;
      }
      .download {
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        .download-btn {
          color: blue;
          // cursor: pointer;
        }
      }
      .jie-name {
        margin-left: 20px;
        line-height: 30px;
        cursor: pointer;
        .free-tip {
          margin-left: 30px;
          background-color: #fffae2;
          color: #f37b1d;
          padding: 2px 5px;
        }
      }
    }
  }
}
</style>